import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CMSLink } from 'components/cmsLink';
import generateUniqueId from 'utilities/generateUniqueId';
import { DonateType } from './DonateType';

const DonateKids = ({ title, donateTypes }) =>
  donateTypes &&
  donateTypes.length > 0 && (
    <section className="donate-kids">
      <div className="donate-kids__frame">
        <h2 className="donate-kids__title">{title}</h2>
        <div className="donate-kids__row">
          {donateTypes.map((donateType) => (
            <div className="donate-kids__column" key={generateUniqueId()}>
              <CMSLink
                linkUrl={donateType.linkUrl}
                className={classNames('cards-row__element', {
                  'cards-row__element--single': donateTypes.length === 1,
                })}
              >
                <DonateType donateType={donateType} />
              </CMSLink>
            </div>
          ))}
        </div>
      </div>
    </section>
  );

DonateKids.propTypes = {
  title: PropTypes.string.isRequired,
  donateTypes: PropTypes.arrayOf(PropTypes.shape()),
};

export { DonateKids };
