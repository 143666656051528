import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/conditionalImage';

const MentorTestimonial = ({ quote }) => (
  <div className="mentor-testimonial">
    <figure className="mentor-testimonial__figure">
      <Image
        image={quote.image && quote.image.children[0].fluid}
        imageText={quote.name}
        className="mentor-testimonial__image"
      />
    </figure>
    <div className="mentor-testimonial__text-wrapper">
      <p className="mentor-testimonial__quote">{quote.quoteText}</p>
      <div className="mentor-testimonial__author">
        <p className="mentor-testimonial__name">{quote.name}</p>
        <p className="mentor-testimonial__title">{quote.title}</p>
      </div>
    </div>
  </div>
);

MentorTestimonial.propTypes = {
  quote: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object]),
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    quoteText: PropTypes.string.isRequired,
  }),
};

export { MentorTestimonial };
