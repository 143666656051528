import React, { useContext } from 'react';

import {
  AcademyContactsRow,
  AcademyTrademark,
} from 'components/academyContacts';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { contactInfo, generateUniqueId } from 'utilities';

const ContactsKids = () => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const contactsAndLocations = contactInfo()[0];
  const academyKidsContacts = contactsAndLocations.frontmatter.contacts.filter(
    (contact) => contact.academy.startsWith('Kids')
  );
  const academyKidsEmail = contactsAndLocations.frontmatter.contacts.find(
    (contact) => contact.academy === 'Kids'
  ).email;

  const phoneNumberLabel = (contact) => {
    switch (contact.academy) {
      case 'Kids - Mentors':
        return translation['contacts.label.mentors'];
      case 'Kids - Partners':
        return translation['contacts.label.partners'];
      default:
        return translation['contacts.label.kids'];
    }
  };

  const renderPhoneNumbers = () =>
    academyKidsContacts &&
    academyKidsContacts.map((contact) => (
      <a
        key={generateUniqueId()}
        className="academy-contacts-row__info-content"
        href={`tel:${contact.phone}`}
      >{`${phoneNumberLabel(contact)}: ${contact.phone} `}</a>
    ));

  return (
    <div className="academy-contacts academy-contacts--wrapper contacts-kids">
      <div className="page__frame">
        <div className="academy-contacts__contacts">
          <AcademyContactsRow email={academyKidsEmail} />
          <p className="academy-contacts-row__info">
            <span className="academy-contacts-row__info-tag">
              {translation['contacts.label.phone']}
            </span>
            <br />
            {renderPhoneNumbers()}
          </p>
        </div>
        <AcademyTrademark trademark={translation['contacts.trademark']} />
      </div>
    </div>
  );
};

export { ContactsKids };
