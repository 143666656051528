import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { languageOptions } from 'lang';
import { Layout } from 'layout';
import { CMSLink } from 'components/cmsLink';
import { VideoGallery } from 'components/videoGallery';
import { HeroBannerInfo } from 'components/heroBanner';
import { MediaLinks } from 'components/mediaLinks';
import {
  AboutKids,
  MentorsTestimonialsSlider,
  HistoryKids,
  DonateKids,
  ContactsKids,
} from './components';

const AcademyKids = ({ data }) => {
  const { markdownRemark } = data;
  const frontmatter = markdownRemark.frontmatter;
  const hasLanguageDropdown = Boolean(markdownRemark.frontmatter.lang);

  const languageContext = useContext(GlobalStateContext);
  languageContext.setLanguage(
    languageOptions.find(
      (lang) => lang.id === markdownRemark.frontmatter.lang.toLowerCase()
    )
  );

  const renderControls = () => (
    <CMSLink className="btn btn--purple" linkUrl={frontmatter.registrationLink}>
      {frontmatter.registrationLinkText}
    </CMSLink>
  );

  return (
    <Layout
      darkTheme
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
      hasLanguageDropdown={hasLanguageDropdown}
    >
      <HeroBannerInfo
        backgroundImage={
          frontmatter.heroBg && frontmatter.heroBg.children[0].fluid.src
        }
        description={frontmatter.shortDescription}
        renderControls={renderControls}
        title={frontmatter.title}
        className="kids-hero-banner"
      />
      <AboutKids
        title={frontmatter.about.title}
        blocks={frontmatter.about.contentBlock}
      />
      <VideoGallery className="inverted" videos={frontmatter.videoGallery} />
      <MentorsTestimonialsSlider
        registrationLinkText={frontmatter.mentors.registrationLinkText}
        title={frontmatter.mentors.title}
        quotes={frontmatter.mentors.quotes}
      />
      <HistoryKids
        events={frontmatter.history.events}
        image={frontmatter.history.image}
        imageDescription={frontmatter.history.imageDescription}
        title={frontmatter.history.title}
      />
      <DonateKids
        title={frontmatter.donate.title}
        donateTypes={frontmatter.donate.types}
      />
      <MediaLinks
        className="inverted"
        links={frontmatter.kidsMediaLinks.links}
        title={frontmatter.kidsMediaLinks.title}
      />
      <ContactsKids />
    </Layout>
  );
};

AcademyKids.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const AcademyKidsQuery = graphql`
  query AcademyKids($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        lang
        title
        shortDescription
        registrationLink
        registrationLinkText
        heroBg {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1700, maxHeight: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        about {
          title
          contentBlock {
            description
            imageDescription
            image {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 799, maxHeight: 533) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        videoGallery {
          title
          thumbnail {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 470, maxHeight: 270) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          videoID
        }
        mentors {
          title
          registrationLinkText
          quotes {
            name
            title
            quoteText
            image {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 1498, maxHeight: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        history {
          title
          image {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 1492, maxHeight: 1108) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          imageDescription
          events {
            image {
              children {
                ... on ImageSharp {
                  fluid(
                    maxWidth: 500
                    maxHeight: 500
                    fit: CONTAIN
                    background: "#ffffff"
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            description
            imageDescription
          }
        }
        donate {
          title
          types {
            title
            description
            linkName
            linkUrl
            icon
          }
        }
        kidsMediaLinks {
          title
          links {
            url
            name
            source
          }
        }
      }
    }
  }
`;

export default AcademyKids;
