import React from 'react';
import PropTypes from 'prop-types';

import generateUniqueId from 'utilities/generateUniqueId';
import { Image } from 'components/conditionalImage';
import DefaultImage from 'images/default-images/kids-lithuania-map.png';
import { HistoryKidsEvent } from './HistoryKidsEvent';

const HistoryKids = ({ image, imageDescription, title, events }) => (
  <section className="history-kids inverted">
    <div className="history-kids__frame">
      <h2 className="history-kids__title">{title}</h2>
      <div className="history-kids__events-wrapper">
        {events && events.length > 0 && (
          <div className="history-kids__events">
            {events.map((event) => (
              <HistoryKidsEvent
                key={generateUniqueId()}
                description={event.description}
                image={event.image}
                imageDescription={event.imageDescription}
              />
            ))}
          </div>
        )}
        <figure className="history-kids__figure">
          <Image
            image={image && image.children[0].fluid}
            imageText={imageDescription}
            className="history-kids__image"
            defaultImage={DefaultImage}
          />
        </figure>
      </div>
    </div>
  </section>
);

HistoryKids.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object]),
  imageDescription: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.object]),
      imageDescription: PropTypes.string.isRequired,
    })
  ),
};

export { HistoryKids };
