import React from 'react';
import PropTypes from 'prop-types';

import CircledArrow from 'icons/circled-arrow.svg';
import { getDonateTypeIcon } from '../getDonateTypeIcon';

const DonateType = ({ donateType }) => (
  <div className="donate-type">
    <div className="donate-type__content">
      <figure className="donate-type__icon">
        <img
          src={getDonateTypeIcon(donateType.icon)}
          alt={`${donateType.icon} icon.`}
        />
      </figure>
      <div className="donate-type__text">
        <h3 className="donate-type__title">{donateType.title}</h3>
        <p className="donate-type__description">{donateType.description}</p>
      </div>
    </div>
    <span className="donate-type__link btn-link btn-link--purple btn-link--circled-arrow">
      {donateType.linkName}
      <CircledArrow />
    </span>
  </div>
);

DonateType.propTypes = {
  donateType: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    linkUrl: PropTypes.string.isRequired,
  }),
};

export { DonateType };
