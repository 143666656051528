import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from 'gatsby';

import { generateUniqueId, getUrlByLanguage } from 'utilities';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import CircledArrow from 'icons/circled-arrow.svg';
import { ACADEMY_KIDS_MENTORS_LANDING } from 'shared/routes';
import { MentorTestimonial } from './MentorTestimonial';

const MentorsTestimonialsSlider = ({ title, registrationLinkText, quotes }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;
  const currentLanguageId = languageContext.language.id;

  const NextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      data-role="none"
      type="button"
      className="mentors-testimonials-slider__next-arrow"
      aria-label={translation['button.arrow.nextSlide']}
    >
      <CircledArrow />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      data-role="none"
      type="button"
      className="mentors-testimonials-slider__prev-arrow"
      aria-label={translation['button.arrow.prevSlide']}
    >
      <CircledArrow />
    </button>
  );

  const settings = {
    autoplay: false,
    adaptiveHeight: true,
    dots: true,
    fade: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 350,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          swipeToSlide: true,
          fade: false,
        },
      },
    ],
  };

  return (
    <div className="mentors-testimonials-slider">
      <div className="mentors-testimonials-slider__frame">
        <h2 className="mentors-testimonials-slider__title">{title}</h2>
        {quotes && quotes.length > 0 && (
          <div className="mentors-testimonials-slider__carousel">
            <Slider {...settings}>
              {quotes.map((quote) => (
                <MentorTestimonial quote={quote} key={generateUniqueId()} />
              ))}
            </Slider>
          </div>
        )}
        <div className="mentors-testimonials-slider__link-wrapper">
          <Link
            className="btn btn--aqua"
            to={getUrlByLanguage(
              currentLanguageId,
              ACADEMY_KIDS_MENTORS_LANDING
            )}
          >
            {registrationLinkText}
          </Link>
        </div>
      </div>
    </div>
  );
};

MentorsTestimonialsSlider.propTypes = {
  title: PropTypes.string.isRequired,
  registrationLinkText: PropTypes.string.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func,
};

export { MentorsTestimonialsSlider };
