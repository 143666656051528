import React from 'react';
import PropTypes from 'prop-types';

import generateUniqueId from 'utilities/generateUniqueId';
import { AboutKidsBlock } from './AboutKidsBlock';

const AboutKids = ({ title, blocks }) => {
  const returnImageSide = (index) => (index % 2 === 1 ? 'left' : 'right');

  return (
    <section className="about-kids inverted">
      <div className="about-kids__frame">
        <h2 className="about-kids__title">{title}</h2>
        {blocks &&
          blocks.length > 0 &&
          blocks.map((block, index) => (
            <AboutKidsBlock
              key={generateUniqueId()}
              description={block.description}
              image={block.image && block.image.children[0].fluid}
              imageText={block.imageDescription}
              imageSide={returnImageSide(index + 1)}
            />
          ))}
      </div>
    </section>
  );
};

AboutKids.propTypes = {
  title: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      imageDescription: PropTypes.string.isRequired,
      image: PropTypes.oneOfType([PropTypes.object]),
    })
  ),
};
export { AboutKids };
