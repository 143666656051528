import React from 'react';
import PropTypes from 'prop-types';

import { SectionImage } from 'components/section/SectionImage';

const AboutKidsBlock = ({ description, image, imageText, imageSide }) => (
  <div className="about-kids-block">
    {imageSide === 'left' && (
      <SectionImage imageSide={imageSide} image={image} imageText={imageText} />
    )}
    {description && description !== '' && (
      <div
        className="about-kids-block__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
    {imageSide === 'right' && (
      <SectionImage imageSide={imageSide} image={image} imageText={imageText} />
    )}
  </div>
);

AboutKidsBlock.defaultProps = {
  imageSide: 'right',
};

AboutKidsBlock.propTypes = {
  description: PropTypes.string.isRequired,
  imageSide: PropTypes.PropTypes.oneOf(['left', 'right']),
  imageText: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
};

export { AboutKidsBlock };
