import PuzzleIcon from 'images/puzzle.svg';
import PercentageIcon from 'images/percentage.svg';
import MentorsIcon from 'images/mentors-icon.svg';

const getDonateTypeIcon = (iconKeyword) => {
  switch (iconKeyword) {
    case 'puzzle':
      return PuzzleIcon;
    case 'percentage':
      return PercentageIcon;
    default:
      return MentorsIcon;
  }
};

export { getDonateTypeIcon };
