import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/conditionalImage';

const HistoryKidsEvent = ({ description, image, imageDescription }) => (
  <div className="history-kids-event">
    <Image
      image={image && image.children[0].fluid}
      imageText={imageDescription}
      className="history-kids-event__icon"
    />
    {description && description !== '' && (
      <div
        className="history-kids-event__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
  </div>
);

HistoryKidsEvent.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  imageDescription: PropTypes.string.isRequired,
};

export { HistoryKidsEvent };
